import yup from 'src/libs/yup';

export const BeneficiaryUnityStoreSchema = yup.object().shape({
  display_name: yup.string().required().label('Nome de Exibição'),
  zip_code: yup.string().required().label('CEP'),
  state: yup.string().required().label('UF'),
  city: yup.string().required().label('Cidade'),
  neighborhood: yup.string().required().label('Bairro'),
  street: yup.string().required().label('Rua/Av'),
  number: yup.string().required().label('Número'),
  complement: yup.string().label('Complemento'),
});

export const BeneficiaryUnityImportSchema = yup.object().shape({
  unities: yup.array().of(
    yup.object().shape({
      id: yup.number().label('ID'),
      display_name: yup.string().required().label('Nome de Exibição'),
      zip_code: yup.string().required().label('CEP'),
      state: yup.string().required().label('UF'),
      city: yup.string().required().label('Cidade'),
      neighborhood: yup.string().required().label('Bairro'),
      street: yup.string().required().label('Rua/Av'),
      number: yup.string().required().label('Número'),
      complement: yup.string().label('Complemento'),
    }),
  ),
});

export const BeneficiaryUnityUpdateSchema = yup.object().shape({
  display_name: yup.string().required().label('Nome de Exibição'),
  zip_code: yup.string().required().label('CEP'),
  state: yup.string().required().label('UF'),
  city: yup.string().required().label('Cidade'),
  neighborhood: yup.string().required().label('Bairro'),
  street: yup.string().required().label('Rua/Av'),
  number: yup.string().required().label('Número'),
  complement: yup.string().label('Complemento'),
});
