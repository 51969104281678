import yup from 'src/libs/yup';

export const PartnerSubsidiaryStoreSchema = yup.object().shape({
  zip_code: yup.string().required().label('CEP'),
  state: yup.string().required().label('UF'),
  city: yup.string().required().label('Cidade'),
  neighborhood: yup.string().required().label('Bairro'),
  street: yup.string().required().label('Rua/Av'),
  number: yup.string().required().label('Número'),
  complement: yup.string().label('Complemento'),
});

export const PartnerSubsidiaryImportSchema = yup.object().shape({
  subsidiaries: yup.array().of(
    yup.object().shape({
      id: yup.number().label('ID'),
      zip_code: yup.string().required().label('CEP'),
      state: yup.string().required().label('UF'),
      city: yup.string().required().label('Cidade'),
      neighborhood: yup.string().required().label('Bairro'),
      street: yup.string().required().label('Rua/Av'),
      number: yup.string().required().label('Número'),
      complement: yup.string().label('Complemento'),
    }),
  ),
});

export const PartnerSubsidiaryUpdateSchema = yup.object().shape({
  zip_code: yup.string().required().label('CEP'),
  state: yup.string().required().label('UF'),
  city: yup.string().required().label('Cidade'),
  neighborhood: yup.string().required().label('Bairro'),
  street: yup.string().required().label('Rua/Av'),
  number: yup.string().required().label('Número'),
  complement: yup.string().label('Complemento'),
});
