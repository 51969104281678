import { FC, useRef, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';

import { DialogContent, DialogTitle } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef } from '@material-ui/data-grid';
import AddIcon from '@material-ui/icons/Add';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import CustomDialog from 'src/components/CustomDialog';
import { CustomDialogRef } from 'src/components/CustomDialog/interfaces';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import InputFile from 'src/components/Form/InputFile';
import LinkButton from 'src/components/LinkButton';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import StatusIcon from 'src/components/Table/StatusIcon';
import usePagination from 'src/hooks/usePagination';
import { IEditBeneficiaryParams } from 'src/interfaces/forms/IBeneficiary';
import { IBeneficiaryUnity } from 'src/interfaces/models/IBeneficiary';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { gerenateReport, ReportSheetInfo } from 'src/utils/reports';
import yupValidate from 'src/utils/yupValidate';
import BeneficiaryUnityFilterSchema from 'src/validators/BeneficiaryUnity/filter.schema';
import { BeneficiaryUnityImportSchema } from 'src/validators/BeneficiaryUnity/save.schema';
import * as XLSX from 'xlsx';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const params = useParams<IEditBeneficiaryParams>();
  const { url } = useRouteMatch();
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading: fetching,
    info,
    loadData,
  } = usePagination<IBeneficiaryUnity>(
    `admin/beneficiaries/${params.id}/unities`,
  );
  const [loading, setLoading] = useState(false);
  const importDialogRef = useRef<CustomDialogRef>(null);

  async function exportData() {
    try {
      setLoading(true);
      const { data: unities } = await api.get<IBeneficiaryUnity[]>(
        `admin/beneficiaries/${params.id}/unities`,
      );

      const unitiesSheet: ReportSheetInfo = {
        columns: [
          'ID',
          'Nome *',
          'CEP *',
          'UF * (Ex: SP)',
          'Cidade *',
          'Bairro *',
          'Rua *',
          'Nº',
          'Complemento',
        ],
        data: [],
        sheetName: `Unidades Beneficiária ${params.id}`,
      };

      for (const unity of unities) {
        unitiesSheet.data.push([
          unity.id || '',
          unity.display_name || '',
          unity.zip_code || '',
          unity.state || '',
          unity.city || '',
          unity.neighborhood || '',
          unity.street || '',
          unity.number || '',
          unity.complement || '',
        ]);
      }

      gerenateReport({
        sheets: [unitiesSheet],
        fileName: `unidades-beneficiaria-${params.id}.xlsx`,
      });
    } catch (error) {
      handleApiResponseErrors(error?.response, 'Erro ao exportar Unidades.');
    } finally {
      setLoading(false);
    }
  }

  async function importData(formData: { file: File }) {
    try {
      setLoading(true);

      if (!formData.file) {
        toast.warn('Insira o arquivo com base no modelo exportado.');
        return;
      }

      const fileData = await formData.file.arrayBuffer();

      const workbook = XLSX.read(fileData);

      const sheetName = workbook.SheetNames[0];

      const sheetData = XLSX.utils
        .sheet_to_json(workbook.Sheets[sheetName], {
          header: [
            'id',
            'display_name',
            'zip_code',
            'state',
            'city',
            'neighborhood',
            'street',
            'number',
            'complement',
          ],
        })
        .slice(1);

      const { success, data } = await yupValidate(
        BeneficiaryUnityImportSchema,
        {
          unities: sheetData,
        },
      );

      if (!success) {
        toast.error('Erro no formato dos dados do arquivo.');
        return;
      }

      await api.post(`admin/beneficiaries/${params.id}/unities/import`, data);

      loadData();
      toast.success('Dados importados com sucesso!');
      importDialogRef.current?.hide();
    } catch (error) {
      handleApiResponseErrors(error?.response, 'Erro ao importar dados');
    } finally {
      setLoading(false);
    }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    { field: 'display_name', headerName: 'Nome', flex: 1 },
    {
      field: 'city',
      headerName: 'Cidade',
      flex: 0.5,
      valueGetter({ row }) {
        return `${row.city} / ${row.state}`;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell(params) {
        return <StatusIcon status={params.row.status} />;
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'right',
      renderCell({ id, row }) {
        return (
          <TableActions
            resource="Filial"
            resourceStatus={row.status}
            editLink={`${url}/${id}`}
            deleteApiUrl={`/admin/beneficiaries/${params.id}/unities/${id}`}
            restoreApiUrl={`/admin/beneficiaries/${params.id}/unities/${id}/restore`}
            descriptionPrefix={`A filial ${row.city} / ${row.state}`}
            onFinish={loadData}
          />
        );
      },
    },
  ];

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={BeneficiaryUnityFilterSchema}
      >
        <Filters />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Box display="flex" gridGap={2}>
              <Button
                startIcon={<CloudDownloadIcon />}
                color="default"
                variant="text"
                size="small"
                onClick={() => exportData()}
                loading={loading}
              >
                Exportar
              </Button>
              <Button
                startIcon={<CloudUploadIcon />}
                color="default"
                variant="outlined"
                size="small"
                loading={loading}
                onClick={() => importDialogRef.current?.show()}
              >
                Importar
              </Button>
            </Box>

            <LinkButton
              to={`${url}/novo`}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
              Nova
            </LinkButton>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={fetching}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <CustomDialog ref={importDialogRef}>
        <DialogTitle style={{ textAlign: 'center' }}>
          Importar Unidades da Beneficiária
        </DialogTitle>

        <Form onSubmit={importData}>
          <DialogContent>
            <InputFile name="file" label="Selecionar Arquivo" />

            <Box mt={1}>
              <Button
                type="submit"
                startIcon={<CloudUploadIcon />}
                color="secondary"
                variant="contained"
                loading={loading}
                fullWidth
              >
                Importar
              </Button>
            </Box>
          </DialogContent>
        </Form>
      </CustomDialog>
    </Container>
  );
};

export default List;
