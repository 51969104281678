import yup from 'src/libs/yup';

export const BeneficiaryStoreSchema = yup.object().shape({
  display_name: yup.string().required().label('Nome de Exibição'),
  slug: yup.string().required().label('URL'),
  short_name: yup.string().label('Nome Curto'),
  logo: yup.mixed().required().isFile().label('Logo'),
  qty_employees: yup.string().label('Qtde Beneficiários'),
  confirm_scholarship: yup.boolean().required(),
  confirm_bonus: yup.boolean().required(),
  confirm_by: yup.string().required().label('Confirmar Vínculo Por'),
  headquarter: yup
    .object()
    .required()
    .shape({
      document: yup.string().required().cnpj().label('CNPJ'),
      company_name: yup.string().required().label('Razão Social'),
      fancy_name: yup.string().label('Fantasia'),
      phone: yup.string().label('Telefone'),
      website: yup.string().label('Site'),
      zip_code: yup.string().label('CEP'),
      state: yup.string().max(2).label('UF'),
      city: yup.string().label('Cidade'),
      neighborhood: yup.string().label('Bairro'),
      street: yup.string().label('Rua/Av'),
      number: yup.string().label('Número'),
      complement: yup.string().label('Complemento'),
    })
    .label('Matriz'),
  voucher_color: yup.string().label('Cor do voucher'),
  voucher_signatory_text: yup.string().label('Assinatura escrita'),
  voucher_signatory_img: yup.mixed().isFile().label('Imagem da assinatura'),
  voucher_stamp_img: yup.mixed().isFile().label('Imagem do carimbo'),
  voucher_logo_align: yup.string().label('Alinhamento da Logo'),
});

export const BeneficiaryUpdateSchema = yup.object().shape({
  display_name: yup.string().required().label('Nome de Exibição'),
  slug: yup.string().required().label('URL'),
  short_name: yup.string().label('Nome Curto'),
  logo: yup.mixed().isFile().label('Logo'),
  qty_employees: yup.string().label('Qtde Beneficiários'),
  confirm_scholarship: yup.boolean().required(),
  confirm_bonus: yup.boolean().required(),
  confirm_by: yup.string().required().label('Confirmar Vínculo Por'),
  headquarter: yup
    .object()
    .required()
    .shape({
      document: yup.string().required().cnpj().label('CNPJ'),
      company_name: yup.string().required().label('Razão Social'),
      fancy_name: yup.string().label('Fantasia'),
      phone: yup.string().label('Telefone'),
      website: yup.string().label('Site'),
      zip_code: yup.string().label('CEP'),
      state: yup.string().max(2).label('UF'),
      city: yup.string().label('Cidade'),
      neighborhood: yup.string().label('Bairro'),
      street: yup.string().label('Rua/Av'),
      number: yup.string().label('Número'),
      complement: yup.string().label('Complemento'),
    })
    .label('Matriz'),
  voucher_color: yup.string().label('Cor do voucher'),
  voucher_signatory_text: yup.string().label('Assinatura escrita'),
  voucher_signatory_img: yup.mixed().isFile().label('Imagem da assinatura'),
  voucher_stamp_img: yup.mixed().isFile().label('Imagem do carimbo'),
  voucher_logo_align: yup.string().label('Alinhamento da Logo'),
});
