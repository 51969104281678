import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { SubmitHandler, FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Select from 'src/components/Form/Select';
import TextField from 'src/components/Form/TextField';
import { IEditPartnerSubsidiaryParams } from 'src/interfaces/forms/IPartner';
import { IPartnerSubsidiary } from 'src/interfaces/models/IPartner';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { statesOptions } from 'src/utils/constants';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import yupValidate from 'src/utils/yupValidate';
import {
  PartnerSubsidiaryStoreSchema,
  PartnerSubsidiaryUpdateSchema,
} from 'src/validators/PartnerSubsidiary/save.schema';

import { Container } from './styles';

const Save: FC = () => {
  const { startLayoutLoading, stopLayoutLoading } = useContext(PrivateContext);
  const formRef = useRef<FormHandles>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const history = useHistory();
  const params = useParams<IEditPartnerSubsidiaryParams>();

  const yupSchema = params.subsidiaryId
    ? PartnerSubsidiaryUpdateSchema
    : PartnerSubsidiaryStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        if (params.subsidiaryId) {
          const userResponse = await api.get(
            `admin/partners/${params.id}/subsidiaries/${params.subsidiaryId}`,
          );
          const subsidiary = userResponse.data as IPartnerSubsidiary;

          setStatus(subsidiary.status);

          formRef.current?.setData(subsidiary);
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, params.subsidiaryId, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (formData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(yupSchema, formData);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.subsidiaryId) {
        await api.put(
          `/admin/partners/${params.id}/subsidiaries/${params.subsidiaryId}`,
          data,
        );
      } else {
        await api.post(`/admin/partners/${params.id}/subsidiaries`, data);
      }

      toast.success('Dados salvos com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);

      if (status === 'active') {
        const isDelete = confirmDialogRef.current?.isDelete();
        let deleteConfig = {};
        if (isDelete) {
          deleteConfig = { data: { delete: true } };
        }

        await api.delete(
          `/admin/partners/${params.id}/subsidiaries/${params.subsidiaryId}`,
          deleteConfig,
        );

        toast.success(`Filial ${isDelete ? 'excluida' : 'desativada'}!`);
      } else {
        await api.post(
          `/admin/partners/${params.id}/subsidiaries/${params.subsidiaryId}/restore`,
        );
        toast.success('Filial restaurada!');
      }
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao atualizar filial.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {params.subsidiaryId ? 'Editar' : 'Nova'} Unidade
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      name="zip_code"
                      label="CEP"
                      mask="zip_code"
                      returnUnmasked
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={3} md={2}>
                    <Select
                      name="state"
                      label="UF"
                      items={statesOptions}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <TextField name="city" label="Cidade" required />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField name="neighborhood" label="Bairro" required />
                  </Grid>

                  <Grid item xs={12} sm={9}>
                    <TextField name="street" label="Rua/Av" required />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField name="number" label="Número" required />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField name="complement" label="Complemento" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="space-between">
              {status === 'active' ? (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.subsidiaryId}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              ) : (
                <Button
                  type="button"
                  loading={loading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.subsidiaryId}
                  onClick={() => confirmDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}

              <Button
                type="submit"
                loading={loading}
                startIcon={<CheckIcon />}
                variant="contained"
                color="primary"
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>

      <ConfirmDialog
        ref={confirmDialogRef}
        title={`${status === 'active' ? 'Desativar' : 'Restaurar'} filial`}
        description="Confirma esta ação?"
        confirmColor={status === 'active' ? 'secondary' : 'primary'}
        onConfirm={handleConfirm}
        canDelete={status === 'active'}
      />
    </Container>
  );
};

export default Save;
