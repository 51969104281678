import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import BackButton from 'src/components/BackButton';
import TabPanel from 'src/components/TabPanel';
import { IEditPartnerParams } from 'src/interfaces/forms/IPartner';

import ContractTab from './ContractTab';
import DataTab from './DataTab';
import ResponsibleTab from './ResponsibleTab';
import ServiceTab from './ServiceTab';
import { Container } from './styles';
import SubsidiaryTab from './SubsidiaryTab';

const PartnerTabs = [
  { name: 'Matriz', slug: '', component: <DataTab /> },
  { name: 'Serviços', slug: 'servicos', component: <ServiceTab /> },
  { name: 'Unidades', slug: 'unidades', component: <SubsidiaryTab /> },
  { name: 'Responsáveis', slug: 'responsaveis', component: <ResponsibleTab /> },
  { name: 'Contratos', slug: 'contratos', component: <ContractTab /> },
];

const Save: FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const params = useParams<IEditPartnerParams>();
  const history = useHistory();

  const handleTabChange = (e: ChangeEvent<any>, newValue: number) => {
    let goToUrl = `/parceiras/${params.id}`;
    if (newValue > 0) {
      goToUrl = goToUrl.concat(`/${PartnerTabs[newValue].slug}`);
    }

    history.replace(goToUrl);
  };

  useEffect(() => {
    if (params.selectedTab) {
      setCurrentTab(
        PartnerTabs.findIndex((tab) => tab.slug === params.selectedTab),
      );
    } else {
      setCurrentTab(0);
    }
  }, [params.selectedTab]);

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <BackButton />

            <Typography variant="h5">
              {params.id ? 'Editar' : 'Nova'} Parceira
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <AppBar color="transparent" position="relative">
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {PartnerTabs.map(({ name }, index) => (
                <Tab
                  key={name}
                  label={name}
                  disabled={index > 0 && !params.id}
                />
              ))}
            </Tabs>
          </AppBar>
        </Grid>

        <Grid item xs={12}>
          {PartnerTabs.map(({ name, component }, index) => (
            <TabPanel key={name} value={currentTab} index={index}>
              {component}
            </TabPanel>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Save;
