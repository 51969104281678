import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import RestoreIcon from '@material-ui/icons/Restore';
import { FormHandles, Scope, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import Button from 'src/components/Button';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import Autocomplete from 'src/components/Form/Autocomplete';
import CheckBox from 'src/components/Form/Checkbox';
import InputColor from 'src/components/Form/InputColor';
import InputFile from 'src/components/Form/InputFile';
import RichText from 'src/components/Form/RichText';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';
import { IEditParams } from 'src/interfaces/IEditParams';
import { IClassification } from 'src/interfaces/models';
import IBeneficiary from 'src/interfaces/models/IBeneficiary';
import PrivateContext from 'src/routes/Private/PrivateContext';
import api from 'src/services/api';
import { statesOptions } from 'src/utils/constants';
import { handleApiResponseErrors, showFormErrors } from 'src/utils/errors';
import { objectToFormData, objectToQuery } from 'src/utils/helpers';
import yupValidate from 'src/utils/yupValidate';
import {
  BeneficiaryStoreSchema,
  BeneficiaryUpdateSchema,
} from 'src/validators/Beneficiary/save.schema';

const HeadquarterTab: FC = () => {
  const voucherLogoAlign: Item[] = [
    { key: 1, label: 'Esquerda', value: 'left' },
    { key: 2, label: 'Centro', value: 'center' },
    { key: 3, label: 'Direita', value: 'right' },
  ];
  const { startLayoutLoading, stopLayoutLoading, layoutLoading } = useContext(
    PrivateContext,
  );
  const formRef = useRef<FormHandles>(null);
  const deleteDialogRef = useRef<ConfirmDialogRef>(null);
  const restoreDialogRef = useRef<ConfirmDialogRef>(null);
  const approveDialogRef = useRef<ConfirmDialogRef>(null);
  const [status, setStatus] = useState('');
  const [classifications, setClassifications] = useState<IClassification[]>([]);
  const history = useHistory();
  const params = useParams<IEditParams>();

  const yupSchema = params.id
    ? BeneficiaryUpdateSchema
    : BeneficiaryStoreSchema;

  useEffect(() => {
    const loadData = async () => {
      try {
        startLayoutLoading();

        const classificationParams = objectToQuery({
          order_by: [{ column: 'name', direction: 'asc' }],
        });
        const classificationsResponse = await api.get(
          `admin/classifications${classificationParams}`,
        );
        const allClassifications = classificationsResponse.data as IClassification[];
        setClassifications(allClassifications);

        if (params.id) {
          const response = await api.get(`admin/beneficiaries/${params.id}`);
          const {
            classifications,
            ...beneficiary
          } = response.data as IBeneficiary;

          setStatus(beneficiary.status);

          const beneficiaryClassifications: boolean[] = [];
          classifications?.forEach((classif) => {
            beneficiaryClassifications[classif.id] = true;
          });

          formRef.current?.setData({
            ...beneficiary,
            classifications: beneficiaryClassifications,
          });
        }
      } catch (error) {
        handleApiResponseErrors(error.response, 'Erro ao buscar dados.');
      } finally {
        stopLayoutLoading();
      }
    };

    loadData();
  }, [params.id, startLayoutLoading, stopLayoutLoading]);

  const handleOnSubmit: SubmitHandler = async (unformData) => {
    try {
      startLayoutLoading();
      formRef.current?.setErrors({});

      const { success, data, errors } = await yupValidate(
        yupSchema,
        unformData,
      );

      const selectedIds: number[] = [];
      unformData.classifications?.forEach(
        (selected: boolean, index: number) => {
          if (selected) selectedIds.push(index);
        },
      );

      data.classifications = selectedIds;

      const formData = objectToFormData(data);

      if (!success) {
        return showFormErrors(errors, formRef);
      }

      if (params.id) {
        await api.put(`/admin/beneficiaries/${params.id}`, formData);
      } else {
        const createResponse = await api.post('/admin/beneficiaries', formData);
        history.replace(`/beneficiarias/${createResponse.data.id}`);
      }

      toast.success('Dados salvos com sucesso!');
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro', yupSchema, formRef);
    } finally {
      stopLayoutLoading();
    }
  };

  const handleConfirmDelete = async () => {
    try {
      startLayoutLoading();

      const isDelete = deleteDialogRef.current?.isDelete();
      let deleteConfig = {};
      if (isDelete) {
        deleteConfig = { data: { delete: true } };
      }

      await api.delete(`/admin/beneficiaries/${params.id}`, deleteConfig);

      toast.success(`Beneficiária ${isDelete ? 'excluida' : 'desativada'}!`);

      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao inativar beneficiária.');
    } finally {
      stopLayoutLoading();
    }
  };

  const handleConfirmApproveOrRestore = async () => {
    try {
      startLayoutLoading();

      await api.post(`/admin/beneficiaries/${params.id}/restore`);
      toast.success('Beneficiária ativada com sucesso!');
      history.goBack();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao ativar beneficiária.');
    } finally {
      stopLayoutLoading();
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleOnSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4}>
                  <InputFile
                    name="logo"
                    accept="image/*"
                    label="Logo"
                    previewAlt="Logo"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={8}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        name="display_name"
                        label="Nome de Exibição"
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField name="short_name" label="Nome Curto" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField name="slug" label="URL" required />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TextField
                            name="qty_employees"
                            label="Qtde Beneficiários"
                            type="number"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Opções da Beneficiária" />

            <CardContent>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    name="confirm_by"
                    label="Confirmar Vínculo Por"
                    options={[
                      { key: 0, label: 'E-mail', value: 'email' },
                      { key: 1, label: 'Painel', value: 'panel' },
                    ]}
                    textFieldProps={{ required: true }}
                    disableClearable
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <CheckBox
                    name="confirm_scholarship"
                    label="Confirmar Bolsa"
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <CheckBox
                    name="confirm_bonus"
                    label="Confirmar Bonus"
                    required
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Matriz" />

            <CardContent>
              <Grid container spacing={1}>
                <Scope path="headquarter">
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="document"
                      label="CNPJ"
                      mask="cnpj"
                      returnUnmasked
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      name="company_name"
                      label="Razão Social"
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField name="fancy_name" label="Nome Fantasia" />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      name="phone"
                      label="Telefone"
                      type="tel"
                      mask="phoneOrCellphone"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <TextField name="website" label="Site" />
                  </Grid>

                  <Grid item xs={12} sm={3} md={2}>
                    <TextField
                      name="zip_code"
                      label="CEP"
                      mask="zip_code"
                      returnUnmasked
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={2}>
                    <Select name="state" label="UF" items={statesOptions} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={8}>
                    <TextField name="city" label="Cidade" />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField name="neighborhood" label="Bairro" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="street" label="Rua / Av." />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField name="number" label="Número" />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <TextField name="complement" label="Complemento" />
                  </Grid>
                </Scope>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Voucher Exclusivo" />

            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <InputFile
                    name="voucher_signatory_img"
                    accept="image/*"
                    label="Imagem da Assinatura"
                    canDelete
                  />
                </Grid>
                <Grid item xs={3}>
                  <InputFile
                    name="voucher_stamp_img"
                    accept="image/*"
                    label="Imagem do Carimbo"
                    canDelete
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputColor name="voucher_color" label="Cor do Voucher" />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        name="voucher_logo_align"
                        label="Alinhamento da Logo"
                        items={voucherLogoAlign}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <RichText name="voucher_signatory_text" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader subheader="Classificações" />

            <CardContent>
              {classifications.map((classification) => (
                <CheckBox
                  key={classification.id}
                  name={`classifications[${classification.id}]`}
                  label={classification.name}
                />
              ))}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="space-between">
            <div>
              {status !== 'inactive' && (
                <Button
                  type="button"
                  loading={layoutLoading}
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="secondary"
                  disabled={!params.id}
                  onClick={() => deleteDialogRef.current?.show()}
                >
                  Desativar
                </Button>
              )}
              {status === 'inactive' && (
                <Button
                  type="button"
                  loading={layoutLoading}
                  startIcon={<RestoreIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => restoreDialogRef.current?.show()}
                >
                  Restaurar
                </Button>
              )}
              {status === 'pending' && (
                <Button
                  type="button"
                  loading={layoutLoading}
                  startIcon={<CheckIcon />}
                  color="primary"
                  disabled={!params.id}
                  onClick={() => approveDialogRef.current?.show()}
                >
                  Aprovar
                </Button>
              )}
            </div>

            <Button
              type="submit"
              loading={layoutLoading}
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={deleteDialogRef}
        title="Desativar beneficiária"
        description="Confirma esta ação?"
        confirmColor="secondary"
        onConfirm={handleConfirmDelete}
        canDelete
      />

      <ConfirmDialog
        ref={restoreDialogRef}
        title="Restaurar beneficiária"
        description="Confirma esta ação?"
        confirmColor="primary"
        onConfirm={handleConfirmApproveOrRestore}
      />

      <ConfirmDialog
        ref={approveDialogRef}
        title="Aprovar beneficiária"
        description="Confirma esta ação?"
        confirmColor="primary"
        onConfirm={handleConfirmApproveOrRestore}
      />
    </Form>
  );
};

export default HeadquarterTab;
